import React from "react";

const PageNotFound = (props) => {
	return (
		<div className="ui grid" style={{ marginTop: "50px" }}>
			<div className="row">
				<div className="column">
					<h1 className="ui header">Page not found</h1>
				</div>
			</div>
		</div>
	);
};
export default PageNotFound;
