import Notify from "bnc-notify";
//const networkId = 4;
const dappId = process.env.REACT_APP_BLOCKNATIVE_ID;

export function initNotify(networkId) {
	const notify = Notify;
	return notify({
		dappId,
		networkId,
	});
}
