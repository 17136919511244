import React from "react";

const Footer = (props) => {
	return (
		<div className="ui vertical footer segment">
			<div className="ui divider"></div>
			<div className="ui bottom secondary menu">
				<div>© 2023 BearerCoin. All rights reserved</div>
				<div className="right menu">
					<a href="mailto:contact@bearercoin.io">contact@bearercoin.io</a>
				</div>
			</div>
		</div>
	);
};

export default Footer;
