import React, { Component } from "react";
import { ethers } from "ethers";
import axios from "axios";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import Factory from "./components/Factory";
import Coin from "./components/Coin";
import PageNotFound from "./components/PageNotFound";
import Footer from "./components/Footer";
import "./App.css";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visitor: false,
			chainId: "",
			accountAddress: "",
			etherBalance: null,
			ethPrice: null,
		};
	}

	componentDidMount() {
		this.getAccountInfo();
		this.getEthPrice();
		setInterval(() => {
			this.getEthPrice();
		}, 60000);

		if (window.ethereum) {
			window.ethereum.on("chainChanged", (chainId) => {
				this.getAccountInfo();
				// window.location.reload();
			});
			window.ethereum.on("accountsChanged", (accounts) => {
				// window.location.reload();
				this.getAccountInfo();
			});
			window.ethereum.on("connect", (mmStatus) => {
				// window.location.reload();
				this.getAccountInfo();
			});
		}
	}

	requestMetamaskAccountInfo = async () => {
		if (window.ethereum) {
			const address = await window.ethereum.request({
				method: "eth_requestAccounts",
			});
			if (address[0] !== undefined) {
				this.getAccountInfo();
			}
		}
	};

	getAccountInfo = async () => {
		// set defaults for account info
		var visitor = true;
		var accountAddress = "Visitor";
		var chainId = "0x4";

		if (window.ethereum) {
			const address = await window.ethereum.request({
				method: "eth_accounts",
			});
			const ethChainId = await window.ethereum.request({
				method: "eth_chainId",
			});
			if (address[0] !== undefined && ethChainId !== undefined) {
				// this user is already logged in via metamask
				accountAddress = ethers.utils.getAddress(address[0]);
				chainId = ethChainId;
				visitor = false;
			}
		}

		this.setState({
			visitor: visitor,
			accountAddress: accountAddress,
			chainId: chainId,
		});

		this.getEtherBalance();
	};

	getEtherBalance = async () => {
		var balance = 0;
		if (window.ethereum) {
			const address = await window.ethereum.request({
				method: "eth_accounts",
			});
			const ethChainId = await window.ethereum.request({
				method: "eth_chainId",
			});
			if (address[0] !== undefined && ethChainId !== undefined) {
				try {
					var provider;
					if (
						/*ethChainId === "0x1" ||
						ethChainId === "0x2a" ||
						ethChainId === "0x3" ||
						ethChainId === "0x4" ||*/
						ethChainId === "0xaa36a7"
					)
						switch (ethChainId) {
							/*case "0x1":
								provider = new ethers.providers.JsonRpcProvider(
									`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`
								);
								break;*/
							case "0xaa36a7":
								provider = new ethers.providers.JsonRpcProvider(
									`https://sepolia.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`
								);
								break;
							default:
						}
					let accountAddress = ethers.utils.getAddress(address[0]);
					balance = await provider.getBalance(accountAddress);
					balance = Number(ethers.utils.formatEther(balance));
				} catch (err) {
					console.log(err.message);
				}
			}
		}
		this.setState({ etherBalance: balance });
	};

	getEthPrice = async () => {
		// axios and etherscan
		const endpoint = `https://api.etherscan.io/api`;

		const priceData = await axios.get(
			endpoint + `?module=stats&action=ethprice&apikey=${process.env.REACT_APP_ETHERSCAN_API_KEY}`
		);
		const price = priceData.data.result.ethusd;

		if (Number(price) !== this.state.ethPrice) {
			this.setState({ ethPrice: Number(price) });
		}
	};

	render() {
		//console.log("App.js rendering");
		if (
			this.state.accountAddress === "" ||
			this.state.chainId === "" ||
			this.state.etherBalance === null ||
			this.state.ethPrice === null
		) {
			return <div />;
		}

		// initial data has loaded
		return (
			<div className="ui container">
				<div className="Site">
					<Router>
						<Route
							path="/"
							render={(props) => (
								<Header
									{...props}
									visitor={this.state.visitor}
									accountAddress={this.state.accountAddress}
									chainId={this.state.chainId}
									requestMetamaskAccountInfo={this.requestMetamaskAccountInfo}
									etherBalance={this.state.etherBalance}
									ethPrice={this.state.ethPrice}
								/>
							)}
						/>
						<div className="Site-content">
							<Switch>
								<Route
									path="/"
									exact
									render={(props) => <Home {...props} chainId={this.state.chainId} />}
								/>
								<Route
									path="/factory"
									render={(props) => (
										<Factory
											{...props}
											visitor={this.state.visitor}
											accountAddress={this.state.accountAddress}
											chainId={this.state.chainId}
											etherBalance={this.state.etherBalance}
											getEtherBalance={this.getEtherBalance}
											ethPrice={this.state.ethPrice}
										/>
									)}
								/>
								<Route
									path="/coin/address/:address"
									exact
									render={(props) => (
										<Coin
											{...props}
											visitor={this.state.visitor}
											accountAddress={this.state.accountAddress}
											chainId={this.state.chainId}
											etherBalance={this.state.etherBalance}
											getEtherBalance={this.getEtherBalance}
											ethPrice={this.state.ethPrice}
										/>
									)}
								/>
								<Route
									path="/coin/address/:address/pw/:pw"
									exact
									render={(props) => (
										<Coin
											{...props}
											visitor={this.state.visitor}
											accountAddress={this.state.accountAddress}
											chainId={this.state.chainId}
											etherBalance={this.state.etherBalance}
											getEtherBalance={this.getEtherBalance}
											ethPrice={this.state.ethPrice}
										/>
									)}
								/>
								<Route path="*" exact={true} component={PageNotFound} />
							</Switch>
						</div>
						<Route path="/" component={Footer} />
					</Router>
				</div>
			</div>
		);
	}
}

export default App;
