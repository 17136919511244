import React, { Component } from "react";
import { ethers } from "ethers";
import axios from "axios";
import { initNotify } from "../helpers/Blocknative";
import QRCode from "qrcode.react";
//import mainnet_factoryAddress from "../contracts/0x1/build/contract-address.json";
//import mainnet_FactoryArtifact from "../contracts/0x1/build/BearerCoinFactory.json";
//import mainnet_CoinArtifact from "../contracts/0x1/build/BearerCoin.json";
import sepolia_factoryAddress from "../contracts/0xaa36a7/build/contract-address.json";
import sepolia_FactoryArtifact from "../contracts/0xaa36a7/build/BearerCoinFactory.json";
import sepolia_CoinArtifact from "../contracts/0xaa36a7/build/BearerCoin.json";
//import ropsten_factoryAddress from "../contracts/0x3/build/contract-address.json";
//import ropsten_FactoryArtifact from "../contracts/0x3/build/BearerCoinFactory.json";
//import ropsten_CoinArtifact from "../contracts/0x3/build/BearerCoin.json";
//import rinkeby_factoryAddress from "../contracts/0x4/build/contract-address.json";
//import rinkeby_FactoryArtifact from "../contracts/0x4/build/BearerCoinFactory.json";
//import rinkeby_CoinArtifact from "../contracts/0x4/build/BearerCoin.json";

class Coin extends Component {
	constructor(props) {
		super(props);
		//visitor: this.props.visitor,
		//accountAddress: this.props.accountAddress,
		//chainId: this.props.chainId,
		//etherBalance: this.props.etherBalance,
		//getEtherBalance = this.props.getEtherBalance,
		//ethPrice: this.props.ethPrice,
		//coin address: {props.match.params.address}
		//coin pw: {props.match.params.pw}

		this.state = {
			jsonProvider: null,
			factoryContract: null,
			factoryAddress: null,
			coinContract: null,
			coinWithSigner: null,
			signerObj: null,

			coinNumber: null,
			coinBalance: null,
			coinOwner: null,
			coinPwHash: null,
			goodCoin: null,

			oldPwTerm: "",
			newPwTerm: "",
			pwEmptyTerm: false,
			ownerEmptyTerm: false,
			pwOwnerEmptyTerm: false,
			addMoneyTerm: "",
			withdrawMoneyTerm: "",
			changeOwnerTerm: "",
			pwChangeOwnerTerm: "",
			sendEthTerm: "",
			pwSendEthTerm: "",
			sendAddressTerm: "",
			pwSendAddressTerm: "",

			addMoneyMessage: "",
			withdrawMoneyMessage: "",
			sendEthMessage: "",
			pwSendEthMessage: "",
			setPwMessage: "",
			changeOwnerMessage: "",
			pwChangeOwnerMessage: "",

			errorMsgType: false,
			loadingMsgType: false,
			successMsgType: false,
			regularMsgType: false,

			activeIndex: 1,
			pwActiveIndex: 1,
			iconType: "outline",

			notify: null,
		};
	}

	componentDidMount() {
		if (this.props.chainId === "0xaa36a7") {
			this.getEthereumInfo();

			let oldPwTerm;
			let newPwTerm;
			if (this.props.match.params.pw !== undefined) {
				oldPwTerm = decodeURIComponent(this.props.match.params.pw);
			} else {
				oldPwTerm = "";
			}
			newPwTerm = this.randomString(
				40,
				"0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
			);

			this.setState({
				oldPwTerm: oldPwTerm,
				newPwTerm: newPwTerm,
			});
			this.initializeNotify();
		}
	}
	randomString = (length, chars) => {
		var result = "";
		for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
		return result;
	};

	componentDidUpdate(prevProps) {
		if (this.props.chainId === "0xaa36a7") {
			if (
				this.props.accountAddress !== prevProps.accountAddress ||
				this.props.chainId !== prevProps.chainId ||
				this.props.etherBalance !== prevProps.etherBalance ||
				this.props.match.params.address !== prevProps.match.params.address
			) {
				this.getEthereumInfo();
			}
		}
	}

	initializeNotify = async () => {
		let notify;
		switch (this.props.chainId) {
			/*
			case "0x1":
				notify = initNotify(1);
				break;
*/
			case "0xaa36a7":
				notify = initNotify(11155111);
				break;
			default:
		}
		this.setState({
			notify: notify,
		});
	};

	getEthereumInfo = async () => {
		let factoryAddress;
		let FactoryArtifact;
		let CoinArtifact;
		var jsonProvider;
		var factoryContract;
		var coinContract;

		switch (this.props.chainId) {
			/*
			case "0x1":
				factoryAddress = mainnet_factoryAddress;
				FactoryArtifact = mainnet_FactoryArtifact;
				CoinArtifact = mainnet_CoinArtifact;
				jsonProvider = new ethers.providers.JsonRpcProvider(
					"https://mainnet.infura.io/v3/800f33e8ff5344f8a36449905ffafa01"
				);
				break;
*/
			case "0xaa36a7":
				factoryAddress = sepolia_factoryAddress;
				FactoryArtifact = sepolia_FactoryArtifact;
				CoinArtifact = sepolia_CoinArtifact;
				jsonProvider = new ethers.providers.JsonRpcProvider(
					`https://sepolia.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`
				);

				break;
/*
			case "0x3":
				factoryAddress = ropsten_factoryAddress;
				FactoryArtifact = ropsten_FactoryArtifact;
				CoinArtifact = ropsten_CoinArtifact;
				jsonProvider = new ethers.providers.JsonRpcProvider(
					`https://ropsten.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`
				);

				break;
			case "0x4":
				factoryAddress = rinkeby_factoryAddress;
				FactoryArtifact = rinkeby_FactoryArtifact;
				CoinArtifact = rinkeby_CoinArtifact;
				jsonProvider = new ethers.providers.JsonRpcProvider(
					`https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`
				);
				break;
*/
			default:
		}

		factoryContract = new ethers.Contract(
			factoryAddress.BearerCoinFactory,
			FactoryArtifact.abi,
			jsonProvider
		);
		coinContract = new ethers.Contract(
			this.props.match.params.address,
			CoinArtifact.abi,
			jsonProvider
		);

		// get signers
		let signerObj;
		let coinWithSigner;

		if (this.props.visitor) {
			signerObj = null;
			coinWithSigner = null;
			//pwSignerObj = null;
			//pwFactoryWithSigner = null;

			/*const randomWallet = ethers.Wallet.createRandom();
			const accountPk = randomWallet.privateKey;
			pwSignerObj = new ethers.Wallet(
				"57b7bab90ceb227282274e2684ce91ea4a13f60f22816dce2247f3e732dc8e5c"
			).connect(jsonProvider);
			pwFactoryWithSigner = new ethers.Contract(
				factoryAddress.BearerCoinFactory,
				FactoryArtifact.abi,
				pwSignerObj
			);
			
			pwSignerObj = new ethers.Wallet(process.env.REACT_APP_FACTORY_OWNER_ACCOUNT_PK).connect(
				jsonProvider
			);
			pwFactoryWithSigner = new ethers.Contract(
				factoryAddress.BearerCoinFactory,
				FactoryArtifact.abi,
				pwSignerObj
			);
			*/
		} else {
			let providerObj = new ethers.providers.Web3Provider(window.ethereum);
			signerObj = providerObj.getSigner();
			coinWithSigner = new ethers.Contract(
				this.props.match.params.address,
				CoinArtifact.abi,
				signerObj
			);
			//pwSignerObj = null;
			//pwFactoryWithSigner = null;

			/*const randomWallet = ethers.Wallet.createRandom();
			const accountPk = randomWallet.privateKey;
			pwSignerObj = new ethers.Wallet(
				"57b7bab90ceb227282274e2684ce91ea4a13f60f22816dce2247f3e732dc8e5c"
			).connect(jsonProvider);
			pwFactoryWithSigner = new ethers.Contract(
				factoryAddress.BearerCoinFactory,
				FactoryArtifact.abi,
				pwSignerObj
			);

			pwSignerObj = new ethers.Wallet(process.env.REACT_APP_FACTORY_OWNER_ACCOUNT_PK).connect(
				jsonProvider
			);
			pwFactoryWithSigner = new ethers.Contract(
				factoryAddress.BearerCoinFactory,
				FactoryArtifact.abi,
				pwSignerObj
			);
			*/
		}

		try {
			const summary = await coinContract.getSummary();
			//console.log("Summary: ", summary);
			const checkCoin = await factoryContract.lookupBearerCoinId(this.props.match.params.address);
			if (Number(checkCoin) === Number(summary[1])) {
				this.setState({
					factoryAddress: summary[0],
					coinNumber: Number(summary[1]),
					coinBalance: Number(ethers.utils.formatEther(summary[2])),
					coinOwner: summary[3],
					coinPwHash: summary[4],
					jsonProvider: jsonProvider,
					factoryContract: factoryContract,
					coinContract: coinContract,
					coinWithSigner: coinWithSigner,
					signerObj: signerObj,
					goodCoin: true,
				});
			}
		} catch (err) {
			this.setState({
				goodCoin: false,
			});
		}
	};

	coinPwHashIsZero() {
		return this.state.coinPwHash ===
			"0x0000000000000000000000000000000000000000000000000000000000000000"
			? true
			: false;
	}

	coinOwnerIsZero() {
		return this.state.coinOwner === "0x0000000000000000000000000000000000000000" ? true : false;
	}

	updateCoinBalancePatiently() {
		const oldCoinBalance = this.state.coinBalance;
		var timesRun = 0;
		var interval = setInterval(() => {
			if (oldCoinBalance === this.state.coinBalance) {
				this.getEthereumInfo();
				this.props.getEtherBalance();
			}
			timesRun += 1;
			if (timesRun === 10) {
				clearInterval(interval);
			}
		}, 1000);
	}

	getFastGasPrice = async () => {
		// axios and etherscan
		let endpoint = `https://api.etherscan.io/api`;
		let priceData = await axios.get(
			endpoint +
				`?module=gastracker&action=gasoracle&apikey=${process.env.REACT_APP_ETHERSCAN_API_KEY}`
		);
		return priceData.data.result.FastGasPrice;
	};

	onClickAddMoney = async (event) => {
		event.preventDefault();
		this.closeMessageBox();

		if (this.props.visitor) {
			this.setState({
				errorMsgType: true,
				addMoneyMessage: "You need to log in to Metamask to send the BearerCoin some ether.",
			});
		} else if (this.props.etherBalance < this.state.addMoneyTerm) {
			this.setState({
				errorMsgType: true,
				addMoneyMessage:
					"Your Metamask account balance is less than the amount you are attempting to add.",
			});
		} else {
			try {
				this.setState({
					loadingMsgType: true,
					addMoneyMessage: "transaction initiated, please confirm in Metamask",
				});

				/*let tx = {
					to: this.props.match.params.address,
					value: ethers.utils.parseEther(this.state.addMoneyTerm),
				};
				let estGas = await this.state.signerObj.estimateGas(tx);
				console.log("estGas: ", Number(estGas));
				*/

				const { hash } = await this.state.signerObj.sendTransaction({
					to: this.props.match.params.address,
					value: ethers.utils.parseEther(this.state.addMoneyTerm),
				});
				//console.log("hash: ", hash);
				const { emitter } = this.state.notify.hash(hash);
				emitter.on("txSent", (event) => {
					this.setState({
						addMoneyMessage: "Transaction has been sent, awaiting confirmation.",
					});
				});
				emitter.on("txConfirmed", (event) => {
					this.setState({
						loadingMsgType: false,
						successMsgType: true,
						addMoneyMessage: "Transaction confirmed.",
					});
					this.updateCoinBalancePatiently();
				});
			} catch (err) {
				this.setState({
					errorMsgType: true,
					addMoneyMessage: err.message,
				});
			}
		}
	};

	onClickWithdrawMoney = async (event) => {
		event.preventDefault();
		this.closeMessageBox();

		if (this.props.visitor) {
			this.setState({
				errorMsgType: true,
				withdrawMoneyMessage: "You need to log in to Metamask to withdraw Ether.",
			});
		} else if (this.state.coinBalance < this.state.withdrawMoneyTerm) {
			this.setState({
				errorMsgType: true,
				withdrawMoneyMessage:
					"Your BearerCoin balance is less than the amount you are attempting to withdraw.",
			});
		} else {
			try {
				this.setState({
					loadingMsgType: true,
					withdrawMoneyMessage: "Transaction initiated, please confirm in Metamask.",
				});

				const { hash } = await this.state.coinWithSigner["withdrawMoney(uint256)"](
					ethers.utils.parseEther(this.state.withdrawMoneyTerm)
				);
				const { emitter } = this.state.notify.hash(hash);
				emitter.on("txSent", (event) => {
					this.setState({
						withdrawMoneyMessage: "Transaction has been sent, awaiting confirmation.",
					});
				});
				emitter.on("txConfirmed", (event) => {
					this.setState({
						loadingMsgType: false,
						successMsgType: true,
						withdrawMoneyMessage: "Transaction confirmed.",
					});
					this.updateCoinBalancePatiently();
				});
			} catch (err) {
				this.setState({
					errorMsgType: true,
					withdrawMoneyMessage: err.message,
				});
			}
		}
	};

	onClickSendEth = async (event) => {
		event.preventDefault();
		this.closeMessageBox();

		if (this.props.visitor) {
			this.setState({
				errorMsgType: true,
				sendEthMessage: "You need to log in to Metamask to send ETH from the BearerCoin.",
			});
		} else if (this.state.coinBalance < this.state.sendEthTerm) {
			this.setState({
				errorMsgType: true,
				sendEthMessage: "Your BearerCoin balance is less than the amount you are trying to send.",
			});
		} else {
			try {
				this.setState({
					loadingMsgType: true,
					sendEthMessage: "Transaction initiated, please confirm in Metamask",
				});

				const { hash } = await this.state.coinWithSigner["transferMoney(address,uint256)"](
					this.state.sendAddressTerm,
					ethers.utils.parseEther(this.state.sendEthTerm)
				);
				const { emitter } = this.state.notify.hash(hash);
				emitter.on("txSent", (event) => {
					this.setState({
						sendEthMessage: "Transaction has been sent, awaiting confirmation.",
					});
				});
				emitter.on("txConfirmed", (event) => {
					this.setState({
						loadingMsgType: false,
						successMsgType: true,
						sendEthMessage: "Transaction confirmed.",
					});
					this.updateCoinBalancePatiently();
				});
			} catch (err) {
				this.setState({
					errorMsgType: true,
					sendEthMessage: err.message,
				});
			}
		}
	};

	onClickSetPassword = async (event) => {
		event.preventDefault();
		this.closeMessageBox();

		if (this.props.visitor) {
			this.setState({
				errorMsgType: true,
				setPwMessage: "you need to log in to Metamask to set the password",
			});
		} else {
			try {
				this.setState({
					loadingMsgType: true,
					setPwMessage: "Setting password, please confirm in Metamask",
				});

				if (this.state.pwEmptyTerm) {
					const { hash } = await this.state.coinWithSigner["setZeroPwHash()"]();
					const { emitter } = this.state.notify.hash(hash);
					emitter.on("txSent", (event) => {
						this.setState({
							setPwMessage: "Set password has been sent, awaiting confirmation",
						});
					});
					emitter.on("txConfirmed", (event) => {
						this.setState({
							loadingMsgType: false,
							successMsgType: true,
							setPwMessage: "Set password confirmed.",
						});
						this.setState({
							newPwTerm: "",
							oldPwTerm: "",
						});
						this.updateCoinBalancePatiently();
					});
				} else {
					var newPwHash = ethers.utils.keccak256(
						ethers.utils.ripemd160(ethers.utils.toUtf8Bytes(this.state.newPwTerm))
					);

					const { hash } = await this.state.coinWithSigner["setPwHash(bytes32)"](newPwHash);
					const { emitter } = this.state.notify.hash(hash);
					emitter.on("txSent", (event) => {
						this.setState({
							setPwMessage: "Set password has been sent, awaiting confirmation",
						});
					});
					emitter.on("txConfirmed", (event) => {
						this.setState({
							loadingMsgType: false,
							successMsgType: true,
							setPwMessage: "Set password confirmed.",
						});
						this.updateCoinBalancePatiently();
						this.setState({
							newPwTerm: "",
							oldPwTerm: "",
						});
					});
				}
			} catch (err) {
				this.setState({
					errorMsgType: true,
					setPwMessage: err.message,
				});
			}
		}
	};

	onClickChangeOwner = async (event) => {
		event.preventDefault();
		this.closeMessageBox();

		if (this.props.visitor) {
			this.setState({
				errorMsgType: true,
				changeOwnerMessage: "You need to log in to Metamask to send ETH from the BearerCoin.",
			});
		} else {
			try {
				this.setState({
					loadingMsgType: true,
					changeOwnerMessage: "transaction initiated, please confirm in Metamask",
				});

				if (this.state.ownerEmptyTerm) {
					const { hash } = await this.state.coinWithSigner["setZeroOwner()"]();
					const { emitter } = this.state.notify.hash(hash);
					emitter.on("txSent", (event) => {
						this.setState({
							changeOwnerMessage: "Transaction has been sent, awaiting confirmation.",
						});
					});
					emitter.on("txConfirmed", (event) => {
						this.setState({
							loadingMsgType: false,
							successMsgType: true,
							changeOwnerMessage: "Transaction confirmed.",
						});
						this.updateCoinBalancePatiently();
					});
				} else {
					const { hash } = await this.state.coinWithSigner["setOwner(address)"](
						this.state.changeOwnerTerm
					);
					const { emitter } = this.state.notify.hash(hash);
					emitter.on("txSent", (event) => {
						this.setState({
							changeOwnerMessage: "Transaction has been sent, awaiting confirmation.",
						});
					});
					emitter.on("txConfirmed", (event) => {
						this.setState({
							loadingMsgType: false,
							successMsgType: true,
							changeOwnerMessage: "Transaction confirmed.",
						});
						this.updateCoinBalancePatiently();
					});
				}
			} catch (err) {
				this.setState({
					errorMsgType: true,
					changeOwnerMessage: err.message,
				});
			}
		}
	};

	onClickPwChangeOwner = async (event) => {
		event.preventDefault();
		this.closeMessageBox();

		let pwCheck = false;
		let pwHash = ethers.utils.keccak256(
			ethers.utils.ripemd160(ethers.utils.toUtf8Bytes(this.state.oldPwTerm))
		);
		if (pwHash === this.state.coinPwHash) {
			pwCheck = true;
		}

		if (!pwCheck) {
			this.setState({
				errorMsgType: true,
				pwChangeOwnerMessage: "Incorrect password.",
			});
		} else {
			// we want to check the coin's balance against cost of transaction

			let gasPrice;
			gasPrice = await this.getFastGasPrice();
			gasPrice = Number(gasPrice);

			let estGas;
			let gasFee;
			if (this.state.pwOwnerEmptyTerm) {
				// estGas for setZeroOwner(address,uint256,address,string,bytes32)
				estGas = 56626;
				gasFee = estGas * gasPrice * 1.1 * 1000000000;
			} else {
				// estGas for setOwner(address,uint256,address,string,bytes32,address)
				estGas = 57530;
				gasFee = estGas * gasPrice * 1.1 * 1000000000;
			}
			if (this.state.coinBalance < Number(ethers.utils.formatEther(String(gasFee)))) {
				this.setState({
					errorMsgType: true,
					pwChangeOwnerMessage: "Your BearerCoin balance is less than the necessary gas amount.",
				});
			} else {
				let chainId = this.props.chainId;
				let coinAddress = this.props.match.params.address;
				//gasFee = gasFee;
				gasPrice = gasPrice * 1000000000;
				let oldPwTerm = this.state.oldPwTerm;
				let newPwHash = ethers.utils.keccak256(
					ethers.utils.ripemd160(ethers.utils.toUtf8Bytes(this.state.newPwTerm))
				);
				let newOwner = this.state.pwChangeOwnerTerm;

				try {
					this.setState({
						loadingMsgType: true,
						pwChangeOwnerMessage: "transaction initiated with BearerCoin as signer",
					});
					if (this.state.pwOwnerEmptyTerm) {
						await axios
							.put(`/api/setZeroOwner`, {
								chainId,
								coinAddress,
								gasFee,
								gasPrice,
								oldPwTerm,
								newPwHash,
							})
							.then(
								(response) => {
									let hash = response.data.hash;
									const { emitter } = this.state.notify.hash(hash);
									emitter.on("txSent", (event) => {
										this.setState({
											pwChangeOwnerMessage: "Transaction has been sent.",
										});
									});
									emitter.on("txConfirmed", (event) => {
										this.setState({
											loadingMsgType: false,
											successMsgType: true,
											pwChangeOwnerMessage: "Owner zero confirmed.",
										});
										this.updateCoinBalancePatiently();
									});
								},
								(error) => {
									console.log("error: ", error);
								}
							);
					} else {
						await axios
							.put(`/api/setOwner`, {
								chainId,
								coinAddress,
								gasFee,
								gasPrice,
								oldPwTerm,
								newPwHash,
								newOwner,
							})
							.then(
								(response) => {
									let hash = response.data.hash;
									const { emitter } = this.state.notify.hash(hash);
									emitter.on("txSent", (event) => {
										this.setState({
											pwChangeOwnerMessage: "Transaction has been sent.",
										});
									});
									emitter.on("txConfirmed", (event) => {
										this.setState({
											loadingMsgType: false,
											successMsgType: true,
											pwChangeOwnerMessage: "Change owner confirmed.",
										});
										this.updateCoinBalancePatiently();
									});
								},
								(error) => {
									console.log("error: ", error);
								}
							);
					}
				} catch (err) {
					this.setState({
						errorMsgType: true,
						pwChangeOwnerMessage: err.message,
					});
				}
			}
		}
	};

	onClickPwSendEth = async (event) => {
		event.preventDefault();
		this.closeMessageBox();

		let pwCheck = false;
		let pwHash = ethers.utils.keccak256(
			ethers.utils.ripemd160(ethers.utils.toUtf8Bytes(this.state.oldPwTerm))
		);
		if (pwHash === this.state.coinPwHash) {
			pwCheck = true;
		}

		if (!pwCheck) {
			this.setState({
				errorMsgType: true,
				pwSendEthMessage: "Incorrect password.",
			});
		} else {
			// we want to check the coin's balance against cost of transaction

			let gasPrice;
			gasPrice = await this.getFastGasPrice();
			gasPrice = Number(gasPrice);

			// estGas for transferMoney(address,uint256,address,string,bytes32,address,uint256)
			let estGas = 62869;
			let gasFee = estGas * gasPrice * 1.1 * 1000000000;

			if (
				this.state.coinBalance <
				Number(this.state.pwSendEthTerm) + Number(ethers.utils.formatEther(String(gasFee)))
			) {
				this.setState({
					errorMsgType: true,
					pwSendEthMessage:
						"Your BearerCoin balance is less than the amount you are trying to send plus the necessary gas amount.",
				});
			} else {
				let chainId = this.props.chainId;
				let coinAddress = this.props.match.params.address;
				//gasFee = gasFee;
				gasPrice = gasPrice * 1000000000;
				let oldPwTerm = this.state.oldPwTerm;
				let newPwHash = ethers.utils.keccak256(
					ethers.utils.ripemd160(ethers.utils.toUtf8Bytes(this.state.newPwTerm))
				);
				let sendAddress = this.state.pwSendAddressTerm;
				let sendEth = Number(ethers.utils.parseEther(this.state.pwSendEthTerm));

				try {
					this.setState({
						loadingMsgType: true,
						pwSendEthMessage: "transaction initiated with BearerCoin as signer",
					});
					await axios
						.put(`/api/transferMoney`, {
							chainId,
							coinAddress,
							gasFee,
							gasPrice,
							oldPwTerm,
							newPwHash,
							sendAddress,
							sendEth,
						})
						.then(
							(response) => {
								let hash = response.data.hash;
								const { emitter } = this.state.notify.hash(hash);
								emitter.on("txSent", (event) => {
									this.setState({
										pwSendEthMessage: "Transaction has been sent.",
									});
								});
								emitter.on("txConfirmed", (event) => {
									this.setState({
										loadingMsgType: false,
										successMsgType: true,
										pwSendEthMessage: "Transaction confirmed.",
									});
									this.updateCoinBalancePatiently();
								});
							},
							(error) => {
								console.log("error: ", error);
							}
						);
				} catch (err) {
					this.setState({
						errorMsgType: true,
						pwSendEthMessage: err.message,
					});
				}
			}
		}
	};

	// messages can be error, loading, success, regular
	closeMessageBox() {
		this.setState({
			addMoneyMessage: "",
			withdrawMoneyMessage: "",
			sendEthMessage: "",
			pwSendEthMessage: "",
			setPwMessage: "",
			chgPwMessage: "",
			changeOwnerMessage: "",
			pwChangeOwnerMessage: "",
			errorMsgType: false,
			loadingMsgType: false,
			successMsgType: false,
			regularMsgType: false,
		});
	}

	renderAddMoneyMessage() {
		if (this.state.errorMsgType && this.state.addMoneyMessage) {
			return (
				<div className="ui negative message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Oops!!</div>
						<p>{this.state.addMoneyMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.loadingMsgType && this.state.addMoneyMessage) {
			return (
				<div className="ui icon message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<i className="notched circle loading icon"></i>
					<div className="content">
						<div className="header">Communicating..</div>
						<p>{this.state.addMoneyMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.successMsgType && this.state.addMoneyMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Success!</div>
						<p>{this.state.addMoneyMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.regularMsgType && this.state.addMoneyMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Message:</div>
						<p>{this.state.addMoneyMessage}</p>
					</div>
				</div>
			);
		}
	}

	renderWithdrawMoneyMessage() {
		if (this.state.errorMsgType && this.state.withdrawMoneyMessage) {
			return (
				<div className="ui negative message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Oops!!</div>
						<p>{this.state.withdrawMoneyMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.loadingMsgType && this.state.withdrawMoneyMessage) {
			return (
				<div className="ui icon message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<i className="notched circle loading icon"></i>
					<div className="content">
						<div className="header">Communicating..</div>
						<p>{this.state.withdrawMoneyMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.successMsgType && this.state.withdrawMoneyMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Success!</div>
						<p>{this.state.withdrawMoneyMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.regularMsgType && this.state.withdrawMoneyMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Message:</div>
						<p>{this.state.withdrawMoneyMessage}</p>
					</div>
				</div>
			);
		}
	}

	renderSendEthMessage() {
		if (this.state.errorMsgType && this.state.sendEthMessage) {
			return (
				<div className="ui negative message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Oops!!</div>
						<p>{this.state.sendEthMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.loadingMsgType && this.state.sendEthMessage) {
			return (
				<div className="ui icon message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<i className="notched circle loading icon"></i>
					<div className="content">
						<div className="header">Communicating..</div>
						<p>{this.state.sendEthMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.successMsgType && this.state.sendEthMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Success!</div>
						<p>{this.state.sendEthMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.regularMsgType && this.state.sendEthMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Message:</div>
						<p>{this.state.sendEthMessage}</p>
					</div>
				</div>
			);
		}
	}

	renderSetPwMessage() {
		if (this.state.errorMsgType && this.state.setPwMessage) {
			return (
				<div className="ui negative message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Oops!!</div>
						<p>{this.state.setPwMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.loadingMsgType && this.state.setPwMessage) {
			return (
				<div className="ui icon message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<i className="notched circle loading icon"></i>
					<div className="content">
						<div className="header">Communicating..</div>
						<p>{this.state.setPwMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.successMsgType && this.state.setPwMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Success!</div>
						<p>{this.state.setPwMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.regularMsgType && this.state.setPwMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Message:</div>
						<p>{this.state.setPwMessage}</p>
					</div>
				</div>
			);
		}
	}

	renderChangeOwnerMessage() {
		if (this.state.errorMsgType && this.state.changeOwnerMessage) {
			return (
				<div className="ui negative message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Oops!!</div>
						<p>{this.state.changeOwnerMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.loadingMsgType && this.state.changeOwnerMessage) {
			return (
				<div className="ui icon message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<i className="notched circle loading icon"></i>
					<div className="content">
						<div className="header">Communicating..</div>
						<p>{this.state.changeOwnerMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.successMsgType && this.state.changeOwnerMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Success!</div>
						<p>{this.state.changeOwnerMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.regularMsgType && this.state.changeOwnerMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Message:</div>
						<p>{this.state.changeOwnerMessage}</p>
					</div>
				</div>
			);
		}
	}

	renderPwChangeOwnerMessage() {
		if (this.state.errorMsgType && this.state.pwChangeOwnerMessage) {
			return (
				<div className="ui negative message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Oops!!</div>
						<p>{this.state.pwChangeOwnerMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.loadingMsgType && this.state.pwChangeOwnerMessage) {
			return (
				<div className="ui icon message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<i className="notched circle loading icon"></i>
					<div className="content">
						<div className="header">Communicating..</div>
						<p>{this.state.pwChangeOwnerMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.successMsgType && this.state.pwChangeOwnerMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Success!</div>
						<p>{this.state.pwChangeOwnerMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.regularMsgType && this.state.pwChangeOwnerMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Message:</div>
						<p>{this.state.pwChangeOwnerMessage}</p>
					</div>
				</div>
			);
		}
	}

	renderPwSendEthMessage() {
		if (this.state.errorMsgType && this.state.pwSendEthMessage) {
			return (
				<div className="ui negative message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Oops!!</div>
						<p>{this.state.pwSendEthMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.loadingMsgType && this.state.pwSendEthMessage) {
			return (
				<div className="ui icon message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<i className="notched circle loading icon"></i>
					<div className="content">
						<div className="header">Communicating..</div>
						<p>{this.state.pwSendEthMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.successMsgType && this.state.pwSendEthMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Success!</div>
						<p>{this.state.pwSendEthMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.regularMsgType && this.state.pwSendEthMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Message:</div>
						<p>{this.state.pwSendEthMessage}</p>
					</div>
				</div>
			);
		}
	}

	copyClick(myText) {
		this.setState({ iconType: "check" });
		navigator.clipboard.writeText(myText);
		setTimeout(
			function () {
				this.setState({ iconType: "outline" });
			}.bind(this),
			3000
		);
	}
	renderPwPanel() {
		if (this.state.newPwTerm !== "") {
			let qrcode = <QRCode size={128} includeMargin value="" />;
			let coinUrlAddrPw = "";
			let clipboardText = "";
			if (typeof window !== "undefined") {
				coinUrlAddrPw =
					window.location.protocol +
					"//" +
					window.location.host +
					"/coin/address/" +
					this.props.match.params.address;
				if (this.state.newPwTerm !== "") {
					coinUrlAddrPw = coinUrlAddrPw + "/pw/" + encodeURIComponent(this.state.newPwTerm);
				}
				qrcode = <QRCode size={128} includeMargin value={coinUrlAddrPw} />;
				clipboardText = "Pw: " + this.state.newPwTerm + "\nUrl: " + coinUrlAddrPw + "\n";
			}

			return (
				<div style={{ marginTop: "10px" }}>
					<div className="ui items">
						<div className="middle aligned item">
							<div className="ui small image">{qrcode}</div>
							<div className="middle aligned content">
								<div className="header">
									Save your new password!{" "}
									<i
										className={`clipboard ${this.state.iconType} icon`}
										title="Copy Password to Clipboard"
										onClick={() => this.copyClick(clipboardText)}
									></i>
								</div>
								<div className="description">
									<table className="ui very compact collapsing table">
										<tbody>
											<tr>
												<td>Password:</td>
												<td>{this.state.newPwTerm}</td>
											</tr>
											<tr>
												<td>Url:</td>
												<td>{coinUrlAddrPw.substring(0, 50) + "..."}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}

	onClickEmptyPw = () => {
		if (this.state.pwEmptyTerm) {
			const newPwTerm = this.randomString(
				40,
				"0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
			);
			this.setState({ pwEmptyTerm: false, newPwTerm: newPwTerm });
		} else {
			this.setState({ pwEmptyTerm: true, newPwTerm: "" });
		}
	};
	onClickEmptyOwner = () => {
		if (this.state.ownerEmptyTerm) {
			this.setState({ ownerEmptyTerm: false });
		} else {
			this.setState({ ownerEmptyTerm: true });
		}
	};
	onTitleClick = (index) => {
		this.setState({ activeIndex: index, pwActiveIndex: 1 });
		if (index === 4) {
			const newPwTerm = this.randomString(
				40,
				"0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
			);
			this.setState({ newPwTerm: newPwTerm });
		}
	};
	renderOwnerInfo() {
		if (this.props.accountAddress === this.state.coinOwner) {
			return (
				<div className="row" style={{ marginBottom: "10px" }}>
					<div className="column">
						<h1 className="ui header">You are the owner</h1>
						<div className="ui top attached tabular menu">
							<a
								className={this.state.activeIndex === 1 ? "item active" : "item"}
								onClick={() => this.onTitleClick(1)}
							>
								Add
							</a>
							<a
								className={this.state.activeIndex === 2 ? "item active" : "item"}
								onClick={() => this.onTitleClick(2)}
							>
								Withdraw
							</a>

							<a
								className={this.state.activeIndex === 3 ? "item active" : "item"}
								onClick={() => this.onTitleClick(3)}
							>
								Send
							</a>
							<a
								className={this.state.activeIndex === 4 ? "item active" : "item"}
								onClick={() => this.onTitleClick(4)}
							>
								Set Password
							</a>
							<a
								className={this.state.activeIndex === 5 ? "item active" : "item"}
								onClick={() => this.onTitleClick(5)}
							>
								Change Owner
							</a>
						</div>
						{(() => {
							if (this.state.activeIndex === 1) {
								return (
									<div className="ui bottom attached segment">
										<div className="ui grid">
											<div className="eight wide column">
												<form onSubmit={this.onClickAddMoney} className="ui form">
													<div className="field">
														<label>Amount of ETH:</label>
														<input
															type="text"
															value={this.state.addMoneyTerm}
															placeholder="1.0"
															onChange={(e) => this.setState({ addMoneyTerm: e.target.value })}
														/>
													</div>
													<div className="field">
														<button className="ui submit button">Add</button>
													</div>
												</form>
												{this.renderAddMoneyMessage()}
											</div>
											<div className="eight wide column">
												<p style={{ marginTop: "10px" }}>
													Add to the BearerCoin from your account.
												</p>
												<p>
													(This BearerCoin will accept Ether from anyone who sends to the
													BearerCoin's address shown above.)
												</p>
											</div>
										</div>
									</div>
								);
							} else if (this.state.activeIndex === 2) {
								return (
									<div className="ui bottom attached segment">
										<div className="ui grid">
											<div className="eight wide column">
												<form onSubmit={this.onClickWithdrawMoney} className="ui form">
													<div className="field">
														<label>Amount of ETH:</label>
														<input
															type="text"
															value={this.state.withdrawMoneyTerm}
															placeholder="1.0"
															onChange={(e) => this.setState({ withdrawMoneyTerm: e.target.value })}
														/>
													</div>
													<div className="field">
														<button className="ui submit button">Withdraw</button>
													</div>
												</form>
												{this.renderWithdrawMoneyMessage()}
											</div>
											<div className="eight wide column">
												<p style={{ marginTop: "10px" }}>
													Withdraw from the BearerCoin to your account.
												</p>
											</div>
										</div>
									</div>
								);
							} else if (this.state.activeIndex === 3) {
								return (
									<div className="ui bottom attached segment">
										<div className="ui grid">
											<div className="eight wide column">
												<form onSubmit={this.onClickSendEth} className="ui form">
													<div className="field">
														<label>Amount of ETH:</label>
														<input
															type="text"
															value={this.state.sendEthTerm}
															placeholder="1.0"
															onChange={(e) => this.setState({ sendEthTerm: e.target.value })}
														/>
													</div>
													<div className="field">
														<label>Ethereum Address:</label>
														<input
															type="text"
															value={this.state.sendAddressTerm}
															placeholder="0xGdF10405716r767cC04020E6A062527Ea781Bafd"
															onChange={(e) => this.setState({ sendAddressTerm: e.target.value })}
														/>
													</div>
													<div className="field">
														<button className="ui submit button">Send</button>
													</div>
												</form>
												{this.renderSendEthMessage()}
											</div>
											<div className="eight wide column">
												<p style={{ marginTop: "10px" }}>
													Send ETH from the BearerCoin to another Ethereum address.
												</p>
											</div>
										</div>
									</div>
								);
							} else if (this.state.activeIndex === 4) {
								return (
									<div className="ui bottom attached segment">
										<div className="ui grid">
											<div className="eight wide column">
												<form onSubmit={this.onClickSetPassword} className="ui form">
													{(() => {
														if (!this.coinPwHashIsZero()) {
															return (
																<div className="field">
																	<div className="ui checkbox">
																		<input
																			type="checkbox"
																			defaultChecked={this.state.pwEmptyTerm}
																			onChange={this.onClickEmptyPw}
																		/>
																		<label>Set Password to None</label>
																	</div>
																</div>
															);
														}
													})()}
													{(() => {
														if (!this.state.pwEmptyTerm || this.coinPwHashIsZero()) {
															return (
																<div className="field">
																	<label>New Password:</label>
																	<input
																		type="text"
																		value={this.state.newPwTerm}
																		placeholder="Enter Password"
																		onChange={(e) => this.setState({ newPwTerm: e.target.value })}
																	/>
																</div>
															);
														}
													})()}
													<div className="field">
														<button className="ui submit button">Set Password</button>
													</div>
												</form>
												{this.renderSetPwMessage()}
											</div>
											<div className="eight wide column">
												<p style={{ marginTop: "10px" }}>
													{this.coinPwHashIsZero()
														? "The password is set to None, so only the owner can interact with the Bearercoin."
														: 'This BearerCoin currently has a password set.  Anybody with the password can now use the anonymous "Password enabled functions" shown below.  As the owner, you can set the password to null or set a different password here.'}
												</p>
												<p>
													The new password shown has been randomly generated to make the BearerCoin
													secure. You can choose your own password if you prefer.
												</p>
											</div>
										</div>
										{this.renderPwPanel()}
									</div>
								);
							} else {
								return (
									<div className="ui bottom attached segment">
										<div className="ui grid">
											<div className="eight wide column">
												<form onSubmit={this.onClickChangeOwner} className="ui form">
													{(() => {
														if (!this.coinPwHashIsZero()) {
															return (
																<div className="field">
																	<div className="ui checkbox">
																		<input
																			type="checkbox"
																			defaultChecked={this.state.ownerEmptyTerm}
																			onChange={this.onClickEmptyOwner}
																		/>
																		<label>Set Owner to None</label>
																	</div>
																</div>
															);
														}
													})()}
													{(() => {
														if (!this.state.ownerEmptyTerm) {
															return (
																<div className="field">
																	<label>New Owner Address:</label>
																	<input
																		type="text"
																		value={this.state.changeOwnerTerm}
																		placeholder="0xebCdf35f657cXe7200988b3602cdE2fba0B0FB1D"
																		onChange={(e) =>
																			this.setState({ changeOwnerTerm: e.target.value })
																		}
																	/>
																</div>
															);
														}
													})()}
													<div className="eight wide column">
														<button className="ui submit button">Change Owner</button>
													</div>
												</form>
												{this.renderChangeOwnerMessage()}
											</div>
											<div className="eight wide column">
												<p style={{ marginTop: "10px" }}>
													Change ownership of this BearerCoin to another address.
												</p>
												<p>If the password has been set, owner can be set to None.</p>
											</div>
										</div>
									</div>
								);
							}
						})()}
					</div>
				</div>
			);
		}
	}

	onTitleClickPw = (index) => {
		this.setState({ pwActiveIndex: index, activeIndex: 1 });
		const newPwTerm = this.randomString(
			40,
			"0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
		);
		this.setState({ newPwTerm: newPwTerm });
	};
	onClickPwEmptyOwner = () => {
		if (this.state.pwOwnerEmptyTerm) {
			this.setState({ pwOwnerEmptyTerm: false });
		} else {
			this.setState({ pwOwnerEmptyTerm: true });
		}
	};

	renderPwInfo() {
		if (!this.coinPwHashIsZero()) {
			return (
				<div className="row" style={{ marginBottom: "10px" }}>
					<div className="column">
						<h1 className="ui header">Password enabled functions (Anonymous)</h1>
						<div className="ui top attached tabular menu">
							<a
								className={this.state.pwActiveIndex === 1 ? "item active" : "item"}
								onClick={() => this.onTitleClickPw(1)}
							>
								Change Owner
							</a>
							<a
								className={this.state.pwActiveIndex === 2 ? "item active" : "item"}
								onClick={() => this.onTitleClickPw(2)}
							>
								Send ETH
							</a>
						</div>
						{(() => {
							if (this.state.pwActiveIndex === 1) {
								return (
									<div className="ui bottom attached segment">
										<div className="ui grid">
											<div className="eight wide column">
												<form onSubmit={this.onClickPwChangeOwner} className="ui form">
													<div className="field">
														<div className="ui checkbox">
															<input
																type="checkbox"
																defaultChecked={this.state.pwOwnerEmptyTerm}
																onChange={this.onClickPwEmptyOwner}
															/>
															<label>Set Owner to None</label>
														</div>
													</div>
													{(() => {
														if (!this.state.pwOwnerEmptyTerm) {
															return (
																<div className="field">
																	<label>New Owner Address:</label>
																	<input
																		type="text"
																		value={this.state.pwChangeOwnerTerm}
																		placeholder="0xebCdf35f657cXe7200988b3602cdE2fba0B0FB1D"
																		onChange={(e) =>
																			this.setState({ pwChangeOwnerTerm: e.target.value })
																		}
																	/>
																</div>
															);
														}
													})()}
													<div className="field">
														<label>Old Password: {this.renderPwSuccessIcon()}</label>
														<input
															type="text"
															value={this.state.oldPwTerm}
															placeholder="Enter Password"
															onChange={(e) => this.setState({ oldPwTerm: e.target.value })}
														/>
													</div>
													<div className="field">
														<label>New Password:</label>
														<input
															type="text"
															value={this.state.newPwTerm}
															placeholder="Enter Password"
															onChange={(e) => this.setState({ newPwTerm: e.target.value })}
														/>
													</div>
													<button className="ui submit button">Change the Owner</button>
												</form>
												{this.renderPwChangeOwnerMessage()}
											</div>
											<div className="eight wide column">
												<p style={{ marginTop: "10px" }}>
													Enter the new owner address. The owner can also be set to none, in which
													case the new password will be the only way to access this BearerCoin.
												</p>
												<p>
													Provide the old password and a new password then click Change the Owner.
													The BearerCoin will pay for the gas, so there needs to be enough ETH in
													the BearerCoin to pay the gas fee.
												</p>
												<p>
													The new password shown has been randomly generated to make the BearerCoin
													secure. You can choose your own password if you prefer.
												</p>
											</div>
										</div>
										{this.renderPwPanel()}
									</div>
								);
							} else {
								return (
									<div className="ui bottom attached segment">
										<div className="ui grid">
											<div className="eight wide column">
												<form onSubmit={this.onClickPwSendEth} className="ui form">
													<div className="field">
														<label>Amount of ETH:</label>
														<input
															type="text"
															value={this.state.pwSendEthTerm}
															placeholder="1.0"
															onChange={(e) => this.setState({ pwSendEthTerm: e.target.value })}
														/>
													</div>
													<div className="field">
														<label>Ethereum Address:</label>
														<input
															type="text"
															value={this.state.pwSendAddressTerm}
															placeholder="0xGdF10405716r767cC04020E6A062527Ea781Bafd"
															onChange={(e) => this.setState({ pwSendAddressTerm: e.target.value })}
														/>
													</div>
													<div className="field">
														<label>Old Password: {this.renderPwSuccessIcon()}</label>
														<input
															type="text"
															value={this.state.oldPwTerm}
															placeholder="Enter Password"
															onChange={(e) => this.setState({ oldPwTerm: e.target.value })}
														/>
													</div>
													<div className="field">
														<label>New Password:</label>
														<input
															type="text"
															value={this.state.newPwTerm}
															placeholder="Enter Password"
															onChange={(e) => this.setState({ newPwTerm: e.target.value })}
														/>
													</div>
													<div className="field">
														<button className="ui submit button">Send</button>
													</div>
												</form>
												{this.renderPwSendEthMessage()}
											</div>
											<div className="eight wide column">
												<p style={{ marginTop: "10px" }}>
													Enter the amount of ETH and the address to send it to.
												</p>
												<p style={{ marginTop: "10px" }}>
													Provide the old password and a new password then click Send. The
													BearerCoin will pay for the gas as well as the transferred ETH, so there
													needs to be enough ETH in the BearerCoin to pay for the transfer plus the
													gas fee.
												</p>
												<p>
													The new password shown has been randomly generated to make the BearerCoin
													secure. You can choose your own password if you prefer.
												</p>
											</div>
										</div>
										{this.renderPwPanel()}
									</div>
								);
							}
						})()}
					</div>
				</div>
			);
		}
	}

	renderPwSuccessIcon() {
		var pwHash = ethers.utils.keccak256(
			ethers.utils.ripemd160(ethers.utils.toUtf8Bytes(this.state.oldPwTerm))
		);
		if (pwHash === this.state.coinPwHash) {
			return <i className="thumbs up outline icon green"></i>;
		} else {
			return <i className="thumbs down outline icon red"></i>;
		}
	}

	currencyFormat = (value) =>
		new Intl.NumberFormat("en-IN", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		}).format(value);

	renderCoinBalance() {
		const coinBalance = this.state.coinBalance.toFixed(4);
		const coinValue = this.currencyFormat(this.state.coinBalance * this.props.ethPrice);
		const balanceString = coinBalance + " ETH (" + coinValue + ")";
		return balanceString;
	}

	render() {
		//console.log("Coin.js rendering");
		if (this.props.chainId !== "0x1" && this.props.chainId !== "0xaa36a7") {
			return <div>Your Metamask network must be set to Sepolia</div>;
		}
		if (this.state.goodCoin === null) {
			return <div>Loading...</div>;
		}

		if (!this.state.goodCoin) {
			return (
				<div className="ui grid">
					<div className="twelve wide column">
						<div className="ui grid" style={{ marginTop: "50px" }}>
							<div className="row" style={{ marginBottom: "10px" }}>
								<div className="column">
									<h1 className="ui header">Bad BearerCoin </h1>
									The address passed into the url above did not correspond to a legitimate
									BearerCoin on this network.
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="ui grid">
					<div className="twelve wide column">
						<div className="ui grid" style={{ marginTop: "50px" }}>
							<div className="row" style={{ marginBottom: "10px" }}>
								<div className="column">
									<h1 className="ui header">BearerCoin #{this.state.coinNumber}</h1>
									BearerCoin provides a physical currency for Ether.
								</div>
							</div>
							<div className="row">
								<div className="column">
									<table className="ui celled table">
										<tbody>
											<tr>
												<td>Coin Balance</td>
												<td>{this.renderCoinBalance()}</td>
											</tr>
											<tr>
												<td>Coin Address</td>
												<td>{this.props.match.params.address}</td>
											</tr>
											<tr>
												<td>Owner address</td>
												<td>{this.coinOwnerIsZero() ? "None" : this.state.coinOwner}</td>
											</tr>
											<tr>
												<td>Password</td>
												<td>{this.coinPwHashIsZero() ? "None" : "Has been set"}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							{this.renderOwnerInfo()}
							{this.renderPwInfo()}
						</div>
					</div>
					<div className="four wide column">
						<div className="ui grid" style={{ marginTop: "50px" }}>
							<div className="row" style={{ marginBottom: "10px" }}>
								<div className="column"></div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

export default Coin;
