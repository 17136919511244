import React from "react";

const Home = (props) => {
	//console.log("props: ", props);
	let textInput = React.createRef();

	function handleClick() {
		window.location.href = "/coin/address/" + textInput.current.value;
	}

	var coinAddress = "";
	// Sepolia
	if (props.chainId === "0xaa36a7") {
		coinAddress = "0x6937Da5e923a92C002370e6b2F70b92AE5a355fB";
	}
	// one day for mainnet
	//	if (props.chainId === "0x1") {
	//		coinAddress = "0x6937Da5e923a92C002370e6b2F70b92AE5a355fB";
	//	}

	return (
		<div className="ui grid" style={{ marginTop: "50px" }} key={props.chainId}>
			<div className="row" style={{ marginBottom: "10px" }}>
				<div className="column">
					<h1 className="ui header">Welcome to BearerCoin!</h1>
					BearerCoin provides a physical currency for Ether.
				</div>
			</div>
			<div className="row">
				<div className="column">
					<h3 className="ui header">Do you want to visit the factory?</h3>
					<button
						className="ui button"
						onClick={(e) => {
							e.preventDefault();
							window.location.href = "/factory";
						}}
					>
						Go to the Factory
					</button>
				</div>
			</div>
			<div className="row">
				<div className="column">
					<h3 className="ui header">Or are you looking for a BearerCoin?</h3>
					<div className="ui right action left icon input">
						<i className="search icon"></i>
						<input
							ref={textInput}
							type="text"
							defaultValue={coinAddress}
							style={{ width: "400px" }}
						/>
					</div>
					<div onClick={handleClick} className="ui button">
						Get
					</div>
				</div>
			</div>
		</div>
	);
};
export default Home;
