import React, { Component } from "react";
import { ethers } from "ethers";
import { Link } from "react-router-dom";
import { initNotify } from "../helpers/Blocknative";
//import mainnet_factoryAddress from "../contracts/0x1/build/contract-address.json";
//import mainnet_FactoryArtifact from "../contracts/0x1/build/BearerCoinFactory.json";
import sepolia_factoryAddress from "../contracts/0xaa36a7/build/contract-address.json";
import sepolia_FactoryArtifact from "../contracts/0xaa36a7/build/BearerCoinFactory.json";
//import ropsten_factoryAddress from "../contracts/0x3/build/contract-address.json";
//import ropsten_FactoryArtifact from "../contracts/0x3/build/BearerCoinFactory.json";
//import rinkeby_factoryAddress from "../contracts/0x4/build/contract-address.json";
//import rinkeby_FactoryArtifact from "../contracts/0x4/build/BearerCoinFactory.json";

class Factory extends Component {
	constructor(props) {
		super(props);
		//visitor: this.props.visitor,
		//accountAddress: this.props.accountAddress,
		//chainId: this.props.chainId,
		//etherBalance: this.props.etherBalance,
		//getEtherBalance = this.props.getEtherBalance,
		//ethPrice: this.props.ethPrice,

		this.state = {
			factoryContract: null,
			factoryWithSigner: null,
			factoryVersion: "",
			factoryNumCoins: null,
			factoryBuildFee: null,
			accountFactoryBalance: null,
			accountNumCoins: null,
			accountArrCoins: [],
			factoryTotalBalance: null,
			factoryArrCoins: [],
			isFactoryOwner: false,

			addToBalanceTerm: "",
			withdrawFromBalanceTerm: "",
			changeOwnerTerm: "",

			addToBalanceMessage: "",
			withdrawFromBalanceMessage: "",
			createBearercoinMessage: "",
			changeOwnerMessage: "",

			errorMsgType: false,
			loadingMsgType: false,
			successMsgType: false,
			regularMsgType: false,

			activeIndex: 1,

			notify: null,
		};
	}

	componentDidMount() {
		if (this.props.chainId === "0xaa36a7") {
			this.getEthereumInfo();
			this.initializeNotify();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.chainId === "0xaa36a7") {
			if (
				this.props.accountAddress !== prevProps.accountAddress ||
				this.props.netVersion !== prevProps.netVersion ||
				this.props.chainId !== prevProps.chainId ||
				this.props.etherBalance !== prevProps.etherBalance
			) {
				this.getEthereumInfo();
			}
			if (this.props.chainId !== prevProps.chainId) {
				this.initializeNotify();
			}
		}
	}

	initializeNotify = async () => {
		let notify;
		switch (this.props.chainId) {
			/*
			case "0x1":
				notify = initNotify(1);
				break;
*/
			case "0xaa36a7":
				notify = initNotify(11155111);
				break;
			default:
		}
		this.setState({
			notify: notify,
		});
	};

	updateAccountFactoryBalancePatiently() {
		const oldAccountFactoryBalance = this.state.accountFactoryBalance;
		let timesRun = 0;
		let interval = setInterval(() => {
			if (oldAccountFactoryBalance === this.state.accountFactoryBalance) {
				this.getEthereumInfo();
				this.props.getEtherBalance();
			}
			timesRun += 1;
			if (timesRun === 10) {
				clearInterval(interval);
			}
		}, 1000);
	}

	getEthereumInfo = async () => {
		let factoryAddress;
		let FactoryArtifact;
		let jsonProvider;
		let factoryContract;

		switch (this.props.chainId) {
			/*
			case "0x1":
				factoryAddress = mainnet_factoryAddress;
				FactoryArtifact = mainnet_FactoryArtifact;
				jsonProvider = new ethers.providers.JsonRpcProvider(
					"https://mainnet.infura.io/v3/800f33e8ff5344f8a36449905ffafa01"
				);
				factoryContract = new ethers.Contract(
					factoryAddress.BearerCoinFactory,
					FactoryArtifact.abi,
					jsonProvider
				);
				break;
*/
			case "0xaa36a7":
				factoryAddress = sepolia_factoryAddress;
				FactoryArtifact = sepolia_FactoryArtifact;
				jsonProvider = new ethers.providers.JsonRpcProvider(
					`https://sepolia.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`
				);
				factoryContract = new ethers.Contract(
					factoryAddress.BearerCoinFactory,
					FactoryArtifact.abi,
					jsonProvider
				);
				break;
/*
			case "0x3":
				factoryAddress = ropsten_factoryAddress;
				FactoryArtifact = ropsten_FactoryArtifact;
				jsonProvider = new ethers.providers.JsonRpcProvider(
					`https://ropsten.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`
				);
				factoryContract = new ethers.Contract(
					factoryAddress.BearerCoinFactory,
					FactoryArtifact.abi,
					jsonProvider
				);
				break;
			case "0x4":
				factoryAddress = rinkeby_factoryAddress;
				FactoryArtifact = rinkeby_FactoryArtifact;
				jsonProvider = new ethers.providers.JsonRpcProvider(
					`https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`
				);
				factoryContract = new ethers.Contract(
					factoryAddress.BearerCoinFactory,
					FactoryArtifact.abi,
					jsonProvider
				);
				break;
*/
			default:
		}

		// get factoryWithSigner
		let signerObj;
		let factoryWithSigner;
		if (this.props.visitor) {
			signerObj = null;
			factoryWithSigner = null;
			/*			const randomWallet = ethers.Wallet.createRandom();
			signerObj = randomWallet.connect(jsonProvider);
			factoryWithSigner = new ethers.Contract(
				factoryAddress.BearerCoinFactory,
				FactoryArtifact.abi,
				signerObj
			);
			*/
		} else {
			let providerObj = new ethers.providers.Web3Provider(window.ethereum);
			signerObj = providerObj.getSigner();
			factoryWithSigner = new ethers.Contract(
				factoryAddress.BearerCoinFactory,
				FactoryArtifact.abi,
				signerObj
			);
		}

		// get factory info
		const summary = await factoryContract.getSummary();
		//console.log("Summary: ", summary);
		const factoryOwner = summary[0];
		const version = summary[1];
		const factoryNumCoins = summary[2];
		const factoryBuildFee = summary[3];
		const factoryTotalBalance = summary[4];

		// get owner info
		let isFactoryOwner = false;
		let factoryArrCoins = [];
		if (this.props.accountAddress === factoryOwner) {
			isFactoryOwner = true;
			factoryArrCoins = await factoryWithSigner.getAllListBearerCoins();
		}

		// get user info
		let accountFactoryBalance = 0;
		let accountNumCoins = 0;
		let accountArrCoins = [];
		if (!this.props.visitor) {
			accountFactoryBalance = await factoryContract.userFactoryBalance(this.props.accountAddress);
			accountNumCoins = await factoryContract.userNumBearerCoins(this.props.accountAddress);
			accountArrCoins = await factoryContract.getUserListBearerCoins(this.props.accountAddress);
		}

		this.setState({
			factoryContract: factoryContract,
			factoryWithSigner: factoryWithSigner,
			factoryVersion: version,
			factoryNumCoins: Number(factoryNumCoins),
			factoryBuildFee: Number(factoryBuildFee),
			factoryArrCoins: factoryArrCoins,
			factoryTotalBalance: Number(ethers.utils.formatEther(factoryTotalBalance)),
			isFactoryOwner: isFactoryOwner,
			accountFactoryBalance: Number(ethers.utils.formatEther(accountFactoryBalance)),
			accountNumCoins: Number(accountNumCoins),
			accountArrCoins: accountArrCoins,
		});
	};

	onClickAddToBalance = async (event) => {
		event.preventDefault();
		this.closeMessageBox();

		if (this.props.visitor) {
			this.setState({
				errorMsgType: true,
				addToBalanceMessage: "You need to log in to Metamask to send the factory some ether.",
			});
		} else if (this.props.etherBalance < this.state.addToBalanceTerm) {
			this.setState({
				errorMsgType: true,
				addToBalanceMessage:
					"Your Metamask account balance is less than the amount you are attempting to add.",
			});
		} else {
			try {
				this.setState({
					loadingMsgType: true,
					addToBalanceMessage: "transaction initiated, please confirm in Metamask",
				});

				const { hash } = await this.state.factoryWithSigner.addFactoryBalance(
					ethers.utils.parseEther(this.state.addToBalanceTerm),
					{
						value: ethers.utils.parseEther(this.state.addToBalanceTerm),
					}
				);
				const { emitter } = this.state.notify.hash(hash);
				emitter.on("txSent", (event) => {
					this.setState({
						addToBalanceMessage: "Transaction has been sent, awaiting confirmation.",
					});
				});
				emitter.on("txConfirmed", (event) => {
					this.setState({
						loadingMsgType: false,
						successMsgType: true,
						addToBalanceMessage: "Transaction confirmed.",
					});
					this.updateAccountFactoryBalancePatiently();
				});
			} catch (err) {
				this.setState({
					errorMsgType: true,
					addToBalanceMessage: err.message,
				});
			}
		}
	};

	onClickWithdrawFromBalance = async (event) => {
		event.preventDefault();
		this.closeMessageBox();

		if (this.props.visitor) {
			this.setState({
				errorMsgType: true,
				withdrawFromBalanceMessage:
					"You need to log in to Metamask to send the factory some ether.",
			});
		} else if (this.state.accountFactoryBalance < this.state.withdrawFromBalanceTerm) {
			this.setState({
				errorMsgType: true,
				withdrawFromBalanceMessage:
					"Your factory balance is less than the amount you are attempting to withdraw.",
			});
		} else {
			try {
				this.setState({
					loadingMsgType: true,
					withdrawFromBalanceMessage: "Transaction initiated, please confirm in Metamask.",
				});

				const { hash } = await this.state.factoryWithSigner.withdrawFactoryBalance(
					ethers.utils.parseEther(this.state.withdrawFromBalanceTerm)
				);
				const { emitter } = this.state.notify.hash(hash);
				emitter.on("txSent", (event) => {
					this.setState({
						withdrawFromBalanceMessage: "Transaction has been sent, awaiting confirmation.",
					});
				});
				emitter.on("txConfirmed", (event) => {
					this.setState({
						loadingMsgType: false,
						successMsgType: true,
						withdrawFromBalanceMessage: "Transaction confirmed.",
					});
					this.updateAccountFactoryBalancePatiently();
				});
			} catch (err) {
				this.setState({
					errorMsgType: true,
					withdrawFromBalanceMessage: err.message,
				});
			}
		}
	};

	onClickCreateBearercoin = async (event) => {
		this.closeMessageBox();
		if (this.props.visitor) {
			this.setState({
				errorMsgType: true,
				createBearercoinMessage: "You need to log in to Metamask to send the factory some ether.",
			});
		} else if (this.state.accountFactoryBalance < 0.1 && !this.state.isFactoryOwner) {
			this.setState({
				errorMsgType: true,
				createBearercoinMessage:
					"You need at least 0.1 ETH in your factory balance to create a BearerCoin.",
			});
		} else {
			try {
				this.setState({
					loadingMsgType: true,
					createBearercoinMessage: "Transaction initiated, please confirm in Metamask.",
				});

				const { hash } = await this.state.factoryWithSigner.createBearerCoin();
				const { emitter } = this.state.notify.hash(hash);
				emitter.on("txSent", (event) => {
					this.setState({
						createBearercoinMessage: "Transaction has been sent, awaiting confirmation.",
					});
				});
				emitter.on("txConfirmed", (event) => {
					this.setState({
						loadingMsgType: false,
						successMsgType: true,
						createBearercoinMessage:
							"Transaction confirmed. your new BearerCoin is shown in the table below.",
					});
					this.updateAccountFactoryBalancePatiently();
				});
			} catch (err) {
				this.setState({
					errorMsgType: true,
					createBearercoinMessage: err.message,
				});
			}
		}
	};

	onClickChangeOwner = async (event) => {
		event.preventDefault();
		this.closeMessageBox();

		if (this.props.visitor) {
			this.setState({
				errorMsgType: true,
				changeOwnerMessage: "You need to log in to Metamask to send from the coin.",
			});
		} else {
			try {
				this.setState({
					loadingMsgType: true,
					changeOwnerMessage: "transaction initiated, please confirm in Metamask",
				});

				const { hash } = await this.state.factoryWithSigner["setOwner(address)"](
					this.state.changeOwnerTerm
				);
				const { emitter } = this.state.notify.hash(hash);
				emitter.on("txSent", (event) => {
					this.setState({
						changeOwnerMessage: "Transaction has been sent, awaiting confirmation.",
					});
				});
				emitter.on("txConfirmed", (event) => {
					this.setState({
						loadingMsgType: false,
						successMsgType: true,
						changeOwnerMessage: "Transaction confirmed.",
					});
					this.updateAccountFactoryBalancePatiently();
				});
			} catch (err) {
				this.setState({
					errorMsgType: true,
					changeOwnerMessage: err.message,
				});
			}
		}
	};

	// messages can be error, loading, success, regular
	closeMessageBox() {
		this.setState({
			addToBalanceMessage: "",
			withdrawFromBalanceMessage: "",
			createBearercoinMessage: "",
			changeOwnerMessage: "",
			errorMsgType: false,
			loadingMsgType: false,
			successMsgType: false,
			regularMsgType: false,
		});
	}

	renderAddToBalanceMessage() {
		if (this.state.errorMsgType && this.state.addToBalanceMessage) {
			return (
				<div className="ui negative message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Oops!!</div>
						<p>{this.state.addToBalanceMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.loadingMsgType && this.state.addToBalanceMessage) {
			return (
				<div className="ui icon message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<i className="notched circle loading icon"></i>
					<div className="content">
						<div className="header">Communicating..</div>
						<p>{this.state.addToBalanceMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.successMsgType && this.state.addToBalanceMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Success!</div>
						<p>{this.state.addToBalanceMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.regularMsgType && this.state.addToBalanceMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Message:</div>
						<p>{this.state.addToBalanceMessage}</p>
					</div>
				</div>
			);
		}
	}

	renderWithdrawFromBalanceMessage() {
		if (this.state.errorMsgType && this.state.withdrawFromBalanceMessage) {
			return (
				<div className="ui negative message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Oops!!</div>
						<p>{this.state.withdrawFromBalanceMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.loadingMsgType && this.state.withdrawFromBalanceMessage) {
			return (
				<div className="ui icon message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<i className="notched circle loading icon"></i>
					<div className="content">
						<div className="header">Communicating..</div>
						<p>{this.state.withdrawFromBalanceMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.successMsgType && this.state.withdrawFromBalanceMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Success!</div>
						<p>{this.state.withdrawFromBalanceMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.regularMsgType && this.state.withdrawFromBalanceMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Message:</div>
						<p>{this.state.withdrawFromBalanceMessage}</p>
					</div>
				</div>
			);
		}
	}

	renderCreateBearercoinMessage() {
		if (this.state.errorMsgType && this.state.createBearercoinMessage) {
			return (
				<div className="ui negative message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Oops!!</div>
						<p>{this.state.createBearercoinMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.loadingMsgType && this.state.createBearercoinMessage) {
			return (
				<div className="ui icon message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<i className="notched circle loading icon"></i>
					<div className="content">
						<div className="header">Communicating..</div>
						<p>{this.state.createBearercoinMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.successMsgType && this.state.createBearercoinMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Success!</div>
						<p>{this.state.createBearercoinMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.regularMsgType && this.state.createBearercoinMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Message:</div>
						<p>{this.state.createBearercoinMessage}</p>
					</div>
				</div>
			);
		}
	}

	renderChangeOwnerMessage() {
		if (this.state.errorMsgType && this.state.changeOwnerMessage) {
			return (
				<div className="ui negative message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Oops!!</div>
						<p>{this.state.changeOwnerMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.loadingMsgType && this.state.changeOwnerMessage) {
			return (
				<div className="ui icon message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<i className="notched circle loading icon"></i>
					<div className="content">
						<div className="header">Communicating..</div>
						<p>{this.state.changeOwnerMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.successMsgType && this.state.changeOwnerMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Success!</div>
						<p>{this.state.changeOwnerMessage}</p>
					</div>
				</div>
			);
		}
		if (this.state.regularMsgType && this.state.changeOwnerMessage) {
			return (
				<div className="ui message">
					<i className="close icon" onClick={() => this.closeMessageBox()}></i>
					<div className="content">
						<div className="header">Message:</div>
						<p>{this.state.changeOwnerMessage}</p>
					</div>
				</div>
			);
		}
	}

	renderFactoryArrCoins() {
		return this.state.factoryArrCoins.map((coin, index) => {
			return (
				<tr key={index}>
					<td>
						<Link to={`/coin/address/${coin}`}>{coin}</Link>
					</td>
				</tr>
			);
		});
	}

	renderAccountArrCoins() {
		if (this.state.accountNumCoins > 0) {
			return this.state.accountArrCoins.map((coin, index) => {
				return (
					<tr key={index}>
						<td>
							<Link to={`/coin/address/${coin}`}>{coin}</Link>
						</td>
					</tr>
				);
			});
		} else {
			return (
				<tr>
					<td>None</td>
				</tr>
			);
		}
	}

	onTitleClick = (index) => {
		this.setState({ activeIndex: index });
	};
	renderAddWithdraw() {
		return (
			<div className="row">
				<div className="column">
					<div className="ui top attached tabular menu">
						<a
							className={this.state.activeIndex === 1 ? "item active" : "item"}
							onClick={() => this.onTitleClick(1)}
						>
							Add to Factory
						</a>
						<a
							className={this.state.activeIndex === 2 ? "item active" : "item"}
							onClick={() => this.onTitleClick(2)}
						>
							Withdraw from Factory
						</a>
					</div>
					{(() => {
						if (this.state.activeIndex === 1) {
							return (
								<div className="ui bottom attached segment">
									<div>
										<form onSubmit={this.onClickAddToBalance} className="ui form">
											<div className="inline field">
												<label>ETH:</label>
												<input
													type="text"
													value={this.state.addToBalanceTerm}
													placeholder="0.1"
													onChange={(e) => this.setState({ addToBalanceTerm: e.target.value })}
												/>
												<button className="ui submit button" style={{ marginLeft: "10px" }}>
													Add
												</button>
											</div>
										</form>
										{this.renderAddToBalanceMessage()}
									</div>
								</div>
							);
						} else {
							return (
								<div className="ui bottom attached segment">
									<div>
										<form onSubmit={this.onClickWithdrawFromBalance} className="ui form">
											<div className="inline field">
												<label>ETH:</label>
												<input
													type="text"
													value={this.state.withdrawFromBalanceTerm}
													placeholder="0.1"
													onChange={(e) =>
														this.setState({ withdrawFromBalanceTerm: e.target.value })
													}
												/>
												<button className="ui submit button" style={{ marginLeft: "10px" }}>
													Withdraw
												</button>
											</div>
										</form>
										{this.renderWithdrawFromBalanceMessage()}
									</div>
								</div>
							);
						}
					})()}
				</div>
			</div>
		);
	}

	currencyFormat = (value) =>
		new Intl.NumberFormat("en-IN", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		}).format(value);
	renderAccountFactoryBalance() {
		const accountFactoryBalance = this.state.accountFactoryBalance.toFixed(4);
		const accountFactoryValue = this.currencyFormat(
			this.state.accountFactoryBalance * this.props.ethPrice
		);
		const balanceString = accountFactoryBalance + " ETH (" + accountFactoryValue + ")";
		return balanceString;
	}

	render() {
		//console.log("Factory.js rendering");
		if (this.props.chainId !== "0x1" && this.props.chainId !== "0xaa36a7") {
			return <div>Your Metamask network must be set to Sepolia</div>;
		}
		if (
			this.state.factoryContract === null ||
			this.state.factoryVersion === null ||
			this.state.factoryNumCoins === null ||
			this.state.accountFactoryBalance === null ||
			this.state.accountNumCoins === null ||
			this.state.accountArrCoins === null
		) {
			return <div>Loading...</div>;
		}

		// initial data has loaded
		return (
			<div className="ui grid">
				<div className="eight wide column">
					<div className="ui grid" style={{ marginTop: "50px" }}>
						<div className="row" style={{ marginBottom: "10px" }}>
							<div className="column">
								<h1 className="ui header">
									BearerCoin Factory Version {this.state.factoryVersion}
								</h1>
								Build yourself a BearerCoin.
							</div>
						</div>

						<div className="row">
							<div className="column">
								<h3 className="ui header">
									Your Factory Balance is {this.renderAccountFactoryBalance()}
									<div className="sub header">You need 0.1 ETH to build a BearerCoin</div>
								</h3>
							</div>
						</div>

						{this.renderAddWithdraw()}

						<div className="row">
							<div className="column">
								<button className="ui button" onClick={() => this.onClickCreateBearercoin()}>
									Create your BearerCoin
								</button>
								{this.renderCreateBearercoinMessage()}
							</div>
						</div>

						<div className="row">
							<div className="column">
								<div className="column">
									<h3 className="ui header">Your BearerCoins:</h3>
									<table className="ui celled table">
										<thead>
											<tr>
												<th>List of BearerCoins you've created</th>
											</tr>
										</thead>
										<tbody>{this.renderAccountArrCoins()}</tbody>
									</table>
								</div>
							</div>
						</div>

						{(() => {
							// little trick to render and call an anonymous function inline
							if (this.state.isFactoryOwner) {
								return (
									<div className="row">
										<div className="column">
											<div className="column">
												<div className="ui divider"></div>
												<h3 className="ui header">Owner info:</h3>
												<table className="ui celled table">
													<thead>
														<tr>
															<th>List of BearerCoins created by this factory</th>
														</tr>
													</thead>
													<tbody>{this.renderFactoryArrCoins()}</tbody>
												</table>
												<table className="ui celled table">
													<tbody>
														<tr>
															<td>Factory Balance Total:</td>
															<td>{this.state.factoryTotalBalance} ETH</td>
														</tr>
														<tr>
															<td colSpan="2">
																<form onSubmit={this.onClickChangeOwner} className="ui form">
																	<div className="field">
																		<label>Change Factory Owner to New Address:</label>
																		<input
																			type="text"
																			value={this.state.changeOwnerTerm}
																			placeholder="0xAd01b51E6A56938545bd7a398A7babac6c598a72"
																			onChange={(e) =>
																				this.setState({ changeOwnerTerm: e.target.value })
																			}
																		/>
																	</div>
																	<div className="eight wide column">
																		<button className="ui submit button">Change Owner</button>
																	</div>
																</form>
																{this.renderChangeOwnerMessage()}
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								);
							}
						})()}
					</div>
				</div>
				<div className="eight wide column">
					<div className="ui grid" style={{ marginTop: "50px" }}>
						<div className="row" style={{ marginBottom: "10px" }}>
							<div className="column">
								<h1 className="ui header">About:</h1>
								<ul>
									<li style={{ padding: "5px 0px" }}>
										Building a BearerCoin costs 0.1 ETH (one tenth of an Ether coin).
									</li>
									<li style={{ padding: "5px 0px" }}>
										Add 0.1 ETH to the Factory from your Ethereum account, and you will be able to
										build a BearerCoin.
									</li>
									<li style={{ padding: "5px 0px" }}>
										You can always withdraw any ETH from the Factory at no charge (over and above
										gas fees - these transactions are written to the blockchain, and that always
										costs ETH).
									</li>
									<li style={{ padding: "5px 0px" }}>
										{this.state.factoryNumCoins} BearerCoins have been created by this Factory. You
										can see the{" "}
										<a
											target="_blank"
											rel="noreferrer"
											href={`https://sepolia.etherscan.io/address/${sepolia_factoryAddress.BearerCoinFactory}`}
										>
											smart contract solidity code
										</a>{" "}
										here.
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default Factory;
