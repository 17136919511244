import React, { Component } from "react";
import { Link } from "react-router-dom";

class Header extends Component {
	constructor(props) {
		super(props);
		//visitor: this.props.visitor
		//accountAddress: this.props.accountAddress,
		//chainId: this.props.chainId,
		//etherBalance: this.props.etherBalance,
		//ethPrice: this.props.ethPrice,
		this.state = {
			iconType: "outline",
		};
	}

	renderAddress() {
		var address = this.props.accountAddress;
		if (this.props.visitor) {
			return "Account: Visitor on Sepolia";
		} else {
			const len = address.length;
			if (len > 9) {
				address = address.substring(0, 6) + "..." + address.substring(len - 4, len);
			}
			return "Account: " + address;
		}
	}

	renderMetamaskButton() {
		if (!(window.ethereum && window.ethereum.isMetaMask)) {
			return (
				<form target="_blank" action="https://metamask.io/download.html">
					<button type="submit" className="ui negative basic button">
						Download and Install Metamask
					</button>
				</form>
			);
		}

		if (this.props.visitor) {
			return (
				<button
					className="ui negative basic button"
					onClick={() => this.props.requestMetamaskAccountInfo()}
				>
					Login to Metamask
				</button>
			);
		}

		switch (this.props.chainId) {
			/*case "0x1":
				return (
					<button className="ui negative basic button">
						Your Metamask network must be Sepolia
					</button>
				);*/
			case "0xaa36a7":
				return (
					<button className="ui positive basic button">Metamask account enabled on Sepolia</button>
				);
			default:
				return (
					<button className="ui negative basic button">
						Your Metamask network must be Sepolia
					</button>
				);
		}
	}

	currencyFormat = (value) =>
		new Intl.NumberFormat("en-IN", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		}).format(value);

	renderBalance() {
		const acctBalance = this.props.etherBalance.toFixed(4);
		const acctValue = this.currencyFormat(this.props.etherBalance * this.props.ethPrice);
		const balanceString = acctBalance + " ETH (" + acctValue + ")";
		return balanceString;
	}

	copyClick(myText) {
		this.setState({ iconType: "check" });
		navigator.clipboard.writeText(myText);
		setTimeout(
			function () {
				this.setState({ iconType: "outline" });
			}.bind(this),
			3000
		);
	}

	render() {
		//console.log("Header.js rendering");
		return (
			<div className="ui container">
				<div className="ui secondary pointing menu" style={{ marginTop: "10px" }}>
					<Link to="/" className="item">
						<h1 className="ui header">BearerCoin</h1>
					</Link>
					<div className="right menu">
						<h4 className="ui header" style={{ marginTop: "6px" }}>
							{this.renderMetamaskButton()}
						</h4>
					</div>
					<div className="right menu">
						<h3 className="ui header">
							<div className="content" style={{ marginTop: "4px" }}>
								<div>
									{this.renderAddress()}
									<i
										className={`clipboard ${this.state.iconType} icon`}
										title="Copy Account Address to Clipboard"
										onClick={() => this.copyClick(this.props.accountAddress)}
									></i>
								</div>
								<div>Balance: {this.renderBalance()}</div>
							</div>
						</h3>
					</div>
				</div>
			</div>
		);
	}
}

export default Header;
